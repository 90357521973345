.ingredient-grid {
  max-width: 1200px;
  margin: 0 auto;
  color: white;
}

.ingredient-grid__heading {
  text-align: left;
}

.ingredient-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.ingredient-link {
  text-decoration: none;
}

.ingredient-card {
  text-align: center;
  padding: 10px;
  text-decoration: none;
  color: white;
}

.ingredient-card__image {
  width: 200px;
  height: auto;
}

.ingredient-card__name {
  margin-top: 10px;
  text-decoration: none;
}

.ingredient-card:hover {
  color: rgb(178, 178, 42);
}
