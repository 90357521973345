.card {
  width: 90%;
  max-width: 250px;
  margin: 10px;
  text-align: center;
  overflow: hidden;
  position: relative;
}

.card__link {
  text-decoration: none;
  color: inherit;
  display: block;
}

.card__image {
  width: 50%;
  height: auto;
  border-radius: 8px;
}

.card__title {
  margin: 10px 0;
  color: white;
  transition: color 0.3s ease;
}

.card:hover .card__title {
  color: rgb(178, 178, 42);
}

.card__image:hover + .card__title {
  color: rgb(178, 178, 42);
}
