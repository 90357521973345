.header {
  background-color: #3c021c;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  color: white;
}

.header__logo img {
  width: 100%;
}

.header__button-container {
  display: flex;
}

.header__search {
  margin-right: 20px;
}

.header__search-button,
.header__home-link {
  background-color: #ff0000;
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  font-size: 16px;
}

.header__search-button:hover,
.header__home-link:hover {
  background-color: #cc0000;
}
