.card-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
}

.card-list__item {
  max-width: calc(25% - 20px);
  margin: 10px 0;
  text-decoration: none;
  color: inherit;
}

.card-list__item img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.card-list__item h3 {
  margin: 10px 0;
}
