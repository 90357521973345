.card-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
  color: white;
}

h2 {
  color: white;
}
.card-list a {
  text-decoration: none;
  color: inherit;
  color: white;
}



.card-list img {
  width: 90%;
  height: auto;
  border-radius: 8px;
}
.col-3 {
  flex: 0 0 calc(25% - 20px); 
  max-width: calc(25% - 20px); 
  margin: 10px 0;
}
.card-list h3 {
  margin: 10px 0;
}


/* Add some padding inside the card container */
.container {
  padding: 2px 16px;
}
