.text-white {
    color: white;
}

.drink-detail-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-left: 100px;
    margin-right: 100px;
}

.drink-info {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 20px;
}

.drink-info-left {
    flex: 0 0 50%;
    max-width: 40%;
    padding-right: 20px;
    text-align: left;
    margin-top: 18px;
}

.drink-info-right {
    flex: 1;
    text-align: left;
    align-items: flex-start; 
    text-align: start;
    margin-top: 18px;
}

.drink-image {
    width: 400px;
    height: auto;
    display: block;
    margin-bottom: 10px;
    margin-top: 10px;
}

.info-center {
    text-align: center;
    margin-top: 18px;
}

.drink-name {
    font-size: 30px;
    font-weight: bold;
    margin-top: 18px;
}

.drink-glass,
.drink-instructions {
    margin-top: 10px;
    text-align: center;
}

.drink-additional-info {
    color: white;
    margin-top: 60px;
    display: flex; 
    flex-direction: column; 
    align-items: flex-center; 
}
