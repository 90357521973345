.text-white {
  color: white;
}

.footer {
  background-color: #731515;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer__content {
  text-align: center; 
}

.footer__heading {
  margin-top: 0;
  margin-bottom: 10px;
}

.alphabet-links {
  display: flex;
}

.alphabet-link {
  text-decoration: none;
  color: white;
  margin: 0 5px;
}
