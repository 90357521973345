.home-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  width: 80%;
  color: white;
  margin: 0 auto;
  margin-top: 60px;
  margin-bottom: 60px;
}

.left-image,
.right-image {
  flex: 0 0 150px;
}

.center-text {
  text-align: center;
  flex: 1;
}

.left-image img,
.right-image img {
  width: 100%;
  max-width: 100%;
  height: auto;
}
