.popular-cocktails-container {
  color: white;
}

.popular-cocktails-container__heading {
  color: white;
  text-align: center;
}

.cocktail-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  color: white;
}

.col-3 {
  flex: 0 0 calc(25% - 20px);
  max-width: calc(25% - 20px);
  margin: 10px 0;
}

.cocktail-card {
  width: 100%;
  text-align: center;
  text-decoration: none;
  color: white;
}

.cocktail-card:hover {
  color: rgb(178, 178, 42);
}

.text-link {
  text-decoration: none;
  color: white;
}

.text-link:hover {
  color: rgb(178, 178, 42);
}

.cocktail-card__image {
  width: 80%;
  object-fit: cover;
  border-radius: 10px;
}

.cocktail-card__name {
  margin-top: 10px;
  font-weight: bold;
  color: white;
  text-decoration: none;
}
